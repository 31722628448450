<template>
  <el-dialog
    title="关于"
    class="about__dialog"
    :visible="value"
    @close="$emit('input', false)"
    width="30%"
    center
  >
    <div style="text-align: center">
      <h3>一款高度简洁且的微信 Markdown 编辑器和图片生成器</h3>
    </div>
    <div style="text-align: center; margin-top: 10px">
      <p>扫码关注公众号，更多好玩的及时推送，还有大厂内推！</p>
      <p><a target="_blank" href="https://curl.qcloud.com/92ixxSWq" style="color: cornflowerblue">本服务托管于2核4G 3年198的腾讯云。</a></p>
      <img
        src="https://images.redream.cn/upic/2019/20220103140517-qrcode_for_gh_27101e9a2f9d_258%20%282%29.jpg"
        style="width: 40%; display: block; margin: 20px auto 10px"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onRedirect('https://github.com/doocs/md')"
        plain
        >GitHub 仓库</el-button
      >
      <el-button
        type="primary"
        @click="onRedirect('https://secreter.github.io/ireader/index.html')"
        plain
        >i微信读书插件</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRedirect(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  min-width: 480px;
}
</style>
